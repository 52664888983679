<template>
    <div>
        <div class="tabs">
            <div class="tab-item" :class="{'active': currentTab === 'compose'}" @click="$emit('changeTab', $event)">
                Compose
            </div>
            <div class="tab-item" :class="{'active': currentTab === 'inbox'}" @click="$emit('changeTab', $event)">
                Inbox
            </div>
        </div>

        <div class="feedback-form" v-if="currentTab === 'compose'">
            <div class="line-one">
                <select :class="fetchCategories" v-model="selectedCategory">
                    <option value="" hidden>Category</option>
                    <option :value="category" v-for="(category,index) in categories" :key="index">{{category}}</option>
                </select>

                <select :class="fetchCategories" v-model="receiverEmail">
                    <option value="" hidden>To</option>
                    <option :value="recipient.email" v-for="(recipient,index) in recipients" :key="index">{{recipient.email}}</option>
                </select>

                <input type="text" placeholder="Subject" v-model="emailSubject">
            </div>

            <div class="line-two">
                <textarea placeholder="Your message" v-model="message"></textarea>

                <button @click="sendMessage()" :disabled="sendingStatus">
                    {{sendingStatus ? 'Sending...' : 'Send message'}}
                </button>
            </div>
        </div>

        <div class="inbox" v-if="currentTab === 'inbox'">

            <div class="page-handler">
                <button @click="$emit('getInboxMessages')">
                    <img src="@/assets/images/refresh.svg" alt=""> Refresh
                </button>
                <button @click="callDelete()">
                    <img src="@/assets/images/delete.svg" alt=""> Delete
                </button>
            </div>

            <div class="messages">
                <!-- TODO: there should exist a filter that can filter stared and all messages -->
                <div v-if="fetchInbox !== 'loading'">
                    <div class="message" @click="$emit('changeComponent', {element: $event, msgId: message.id});"
                    v-for="message in inboxMessages" :key="message.id">
                        <input type="checkbox" class="checkbox" @click="selectMessage(message.id)">

                    <!-- TODO: work on selecting the star and create a ui for starred messages
                    if not hide this starred icon. -->
                    <!-- TODO: for this starring to work well nsima would create a starred property
                    on each messages so that when the starred is clicked i can create a function 
                    that would send the id of message as starred, and I can apply the class, not based
                    on a general property starred, but based on the message starred property. -->
                    
                        <!-- <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" 
                        @click="starred = !starred" :class="[starred === true ? 'selected' : '','svg-star']">
                            <path d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                            fill="none" stroke="#6C6C6C80" stroke-width="1" class="svg-star"/>
                        </svg> -->

                        <p class="subject"><span class="subject-text">{{message.subject}}</span><span> -</span></p>
                        
                        <p class="body">{{message.message}}</p>

                        <p class="date">{{formatDate(message.created_at)}}</p>
                    </div>
                </div>
                <div v-if="fetchInbox === 'loading'" class="center-text">
                    <Loader />
                </div>
            </div>
        </div>
        <div class="modal-bg" v-if="confirmDelete">
            <div class="modal-content">
                <p>
                    Are you sure you want to delete this
                    {{aboutToDeleteMessages.length > 1 ? aboutToDeleteMessages.length + ' messages' : 'message'}}?
                </p>
                <div class="action">
                    <button class="cancel" @click="confirmDelete = false;">Cancel</button>
                    <button class="delete" @click="deleteMessages()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import messages from '../services/messages';

import Loader from './Loader';

export default {
    name: "FeedbackMain",
    components: {
        Loader,
    },
    data() {
        return {
            starred: false,
            userID: null,
            userEmail: null,
            categories: [],
            recipients: [],
            fetchCategories: "",
            selectedCategory: "",
            receiverEmail: "",
            emailSubject: "",
            message: "",
            sendingStatus: false,
            aboutToDeleteMessages: [],
            confirmDelete: false,
        }
    },
    props: {
        currentTab: String,
        inboxMessages: Array,
        fetchInbox: String,
    },
    methods: {
        sendMessage() {
            if (this.selectedCategory.length === 0) {
                return this.$toast.error("select a category");
            }
            if (this.receiverEmail.length === 0) {
                return this.$toast.error("select receiver's email");
            }
            if (this.emailSubject.length === 0) {
                return this.$toast.error("Add a subject to your message");
            }
            if (this.message.length === 0) {
                return this.$toast.error("Add a message")
            }
            let body = JSON.stringify({
                senderemail: this.userEmail,
                receiveremail: this.receiverEmail,
                subject: this.emailSubject,
                category: this.selectedCategory,
                message: this.message
            })
            
            this.sendingStatus = true;
            messages.sendMessage(body)
            .then(data => {
                if (data.success) {
                    this.$toast.success(data.success);
                    this.resetFields();
                } else {
                    this.$toast.error(data.error);
                }
            })
            .catch((err) => {
                console.log(err);
                // this.$toast.error("please check your network and refresh the page");
            })
            .finally(() => {
                this.sendingStatus = false;
            })
        },
        resetFields() {
            this.receiverEmail = "";
            this.emailSubject = "";
            this.selectedCategory = "";
            this.message = "";
        },
        formatDate(date) {
            date = new Date(date);
            let year = date.getFullYear();
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let month = months[date.getMonth()];
            let day = date.getDay();
            day = day.toString();
            day = day.length > 1 ? day : '0' + day;
            return month + " " + day + ", " + year;
        },
        selectMessage(id) {
            this.aboutToDeleteMessages.push(id);
        },
        callDelete() {
            if (this.aboutToDeleteMessages.length === 0) {
                return this.$toast.warning('Select a message to delete');
            }

            this.confirmDelete = true;
        },
        deleteMessages() {
            this.confirmDelete = false;
            let body = {
                ids: JSON.stringify(this.aboutToDeleteMessages)
            }
            body = JSON.stringify(body);
            messages.deleteMessages(body)
            .then(data => {
                if (data.success) {
                    this.$toast.success(data.success);
                    this.$emit('getInboxMessages');
                } else {
                    this.$toast.error(data.error);
                }
            })
            .catch((err) => {
                console.log(err);
                // this.$toast.error("please check your network and refresh the page");
            })
        }
    },
    created() {
        let user = this.$store.getters.getUser;
        this.userID = user.id;
        this.userEmail = user.email;

        this.fetchCategories = 'loading';
        messages.getCategories(this.userID)
        .then((data) => {
            if (data.success) {
                this.categories = data.mailcategories;
                this.recipients = data.recepients;
            } else {
                this.$toast.error(data.error);
            }
        })
        .catch((err) => {
            console.log(err);
            // this.$toast.error("please check your network and refresh the page");
        })
        .finally(() => {
            this.fetchCategories = 'loaded';
        })
    }
}
</script>

<style scoped>
    .tabs {
        margin-top: 36px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        display: flex;
    }
    .tab-item {
        cursor: pointer;
        font-size: 18px;
        font-family: "Gotham-Book";
        line-height: 1.0;
        padding: 0 10px 15px 5px;
        margin-bottom: -1px;
        margin-right: 30px;
    }
    .tab-item.active {
        font-weight: 900;
        border-bottom: 3px solid var(--primary-color);
    }

    /* feedback form */

    .feedback-form {
        background: #fff;
        width: 100%;
        margin-top: 37px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .feedback-form .line-one {
        padding: 20px 64px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: space-between;
    }

    .feedback-form .line-two {
        padding: 30px 64px 50px;
    }

    .feedback-form select {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        width: 30%;
        height: 40px;
    }

    select.loading {
        background: url('../assets/images/spinner.gif') no-repeat;
        background-size: 20px;
        background-position: right 20px center;
    }

    .feedback-form input {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        width: 30%;
        height: 40px;
        padding-left: 3px;
    }

    .feedback-form textarea {
        margin-bottom: 60px;
        height: 250px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.5);
        padding: 20px;
        font-family: "Gotham-Book";
        font-size: 14px;
    }

    .feedback-form input:focus, .feedback-form select:focus {
        outline: 0;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 1);
    }

    .feedback-form textarea:focus {
        outline: 1px solid lightblue;
    }

    .feedback-form button {
        background: var(--green-color);
        width: 164px;
        height: 50px;
        color: #fff;
        font-family: "Gotham-Book";
        font-size: 14px;
    }
    .feedback-form button:focus {
        outline: 2px solid var(--deep-green);
    }


    /* inbox */
    .inbox {
        margin-top: 25px;
    }
    .page-handler {
        display: flex;
        justify-content: flex-end;
    }
    .page-handler button:first-of-type {
        margin-right: 12px;
    }
    .page-handler button {
        color: #000;
        border: 1px solid #000;
        border-radius: 5px;
        background: #fff;
        width: 89px;
        height: 25px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .page-handler button img {
        margin-right: 10px;
    }
    .messages {
        background: #FFFFFF;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-top: 25px;
    }
    .messages > div:nth-of-type(even) {
        background: #F5F5F5;
    }
    .messages > div:nth-of-type(odd) {
        background-color: #fff;
    }
    .message {
        display: grid;
        /* grid-template-columns: 40px 40px 100px 1fr 100px; */
        /* grid-template-columns: 40px 40px 3fr 6fr 100px; */
        grid-template-columns: 40px 3fr 6fr 100px;
        justify-items: center;
        align-items: center;
        padding: 0 10px;
        height: 50px;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 600;
        cursor: pointer;
        color: #000;
    }
    
    .message:hover {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        position: relative;
        z-index: 1;
    }
    .message svg {
        cursor: pointer;
    }
    svg.selected path{
        fill: #c6c6c6;
    }
    .message .subject, .message .date {
        display: flex;
        align-items: flex-end;
        font-family: "Gotham-Book";
        font-weight: 600;
    }
    .message .subject {
        max-width: fit-content;
        width: 100%;
    }
    .message .body, .message .subject-text {
        width: 100%;
        text-overflow: ellipsis;

        white-space: nowrap;
        overflow-x: hidden;
    }
    .message .subject span {
        display: inline-block;
        line-height: 1.5;
    }
    .message .subject-text {
        width: calc(100% - 10px);
        max-width: fit-content;
    }
    @media (max-width: 991.98px) {
        /* feedback form */
        .feedback-form .line-one {
            padding: 20px 30px;
        }

        .feedback-form .line-two {
            padding: 30px 30px 50px;
        }
        .feedback-form textarea {
            margin-bottom: 40px;
            height: 200px;
        }
    }
    @media (max-width: 575.98px) {
        /* feedback form */
        .feedback-form .line-one {
            display: flex;
            flex-direction: column;
        }
        .feedback-form select {
            width: 100%;
            margin-bottom: 10px;
        }
        .feedback-form input {
            width: 100%;
        }

        /* inbox */
        .message {
            display: grid;
            grid-template-columns: 30px 30px 80px 1fr 80px;
            justify-items: center;
            align-items: center;
            padding: 0 5px;
            height: 40px;
            font-size: 12px;
        }
        .message .subject-text {
            width: 65px;
        }
    }
    @media (max-width: 374.98px) {
        /* feedback form */
        .feedback-form textarea {
            height: 150px;
            margin-bottom: 30px;
            padding: 20px 10px;
        }
        .feedback-form button {
            height: 40px;
        }
    }




    /* modal section styling */
    .modal-bg {
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
    }
    .modal-content {
        font-family: "Gotham-Book";
        background: #fff;
        width: 70%;
        max-width: 450px;
        height: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        border-radius: 10px;
    }
    .modal-content p {
        margin-bottom: 20px;
    }
    .modal-content .action {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .modal-content button {
        height: 30px;
        width: 90px;
        border-radius: 5px;
        color: #fff;
        transition: all 0.25s;
    }
    .modal-content button:focus {
        outline: 0;
    }
    .modal-content .cancel {
        background: var(--deep-green);
        border: 1px solid var(--deep-green);
    }
    .cancel:hover {
        background: #2e8339;
    }
    .cancel:focus {
        box-shadow: 0 0 0 2px rgba(46, 131, 57, 0.582);
    }
    .modal-content .delete {
        border: 1px solid rgba(255, 0, 0, 0.774);
        background: rgba(255, 0, 0, 0.774);
    }
    .delete:hover {
        background: rgba(218, 0, 0, 0.774);
    }
    .delete:focus {
        box-shadow: 0 0 0 2px rgba(218, 0, 0, 0.548);
    }
</style>